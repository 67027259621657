import './GioiThieu.scss'

export const GioiThieu = (props) => {
  return (
    <>
      <div id='call-hotline' className='text-center'>
        <a href="tel:0932.046.246">
          <img className='hotline' src='img/hotline.gif' alt='so hotline'/>
        </a>
      </div>
      <div id="gioi-thieu">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/hinhanhtaxxi.png" className="img-responsive" alt="dulichankhang" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="gioi-thieu-text">
                <h2>Tìm kiếm và đặt xe taxi</h2>
                <p>{props.data ? props.data.text1 : "loading..."}</p>
                <p>{props.data ? props.data.text2 : "loading..."}</p>
                <p>{props.data ? props.data.text3 : "loading..."}</p>
                <h3>Tại sao nên chọn chúng tôi?</h3>
                <div className="list-style">
                    <ul>
                      {props.data
                        ? props.data.reasons.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
