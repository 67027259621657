import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './CarouselImages.scss'

export const CarouselImages = ({images}) => {
  return (
    <div className='images-carousel'>
      <Carousel showArrows={true} interval={2500} autoPlay infiniteLoop>
        {images.map((image) => (
          <div>
            <img className="carousel-image" src={image.smallImage} />
            <p className="legend">{image.title}</p>
            <p className="legend-des">{image.description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  )
}


