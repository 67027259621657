export const PhanHoi = (props) => {
  return (
    <div id='phan-hoi'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Khách hàng</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  <div className='phan-hoi-item'>
                    <div className='phan-hoi-item-image'>
                      {' '}
                      <img src={d.img} alt='khach hang phan hoi' />{' '}
                    </div>
                    <div className='phan-hoi-item-content'>
                      <p>"{d.text}"</p>
                      <div className='phan-hoi-item-meta'>{d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
