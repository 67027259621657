import {CarouselImages} from './CarouselImages'

import './DiaDiem.scss'

export const DiaDiem = (props) => {
  console.log('====props 1 '. props)
  return (
    <div id='dia-diem' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Bạn muốn đi đâu?</h2>
          <p>
            BẠN MUỐN GỌI XE Ở KHU VỰC NÀO? VUI LÒNG CHỌN ĐIỂM ĐÓN
          </p>
        </div>
        <div id='row' className='desktop'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-md-3 col-sm-6 dia-diem'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.smallImage} alt='dia diem dua don' className='dia-diem-img' />
                    <div className='caption'>
                      <h4>{d.title}</h4>
                      <p>{d.description}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
        <div className='mobile'>
          {props.data && <CarouselImages images={props.data}/>}
        </div>
      </div>
    </div>
  )
}
