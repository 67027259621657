import React, { useState } from "react";
import emailjs from 'emailjs-com'
import DatePicker from "react-datepicker";
import vi from 'date-fns/locale/vi';
import { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import './TopHeader.scss'

registerLocale('vi', vi)

const initialState = {
  name: '',
  soDienThoai: '',
  message: '',
}
export const TopHeader = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isSent, setIsSent] = useState(false)
  const [{ name, soDienThoai, message }, setState] = useState(initialState)

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    if (isSent === 'loading') {
      return
    }
    e.preventDefault()
    setIsSent('loading')
    emailjs
      .sendForm(
        'service_nhk0qx1', 'template_1zw9ytg', e.target, 'user_sACUySubu59xIIMdarQlD'
      )
      .then(
        (result) => {
          e.target.reset()
          console.log(result.text)
          clearState()
          setIsSent(true)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='contact-form'>
                  <label>Điểm đón</label>
                  <input
                    type='text'
                    id='from_loc'
                    name='from_loc'
                    className='form-control'
                    placeholder='Nhập điểm đón'
                    autocomplete="off"
                    required
                  />
                  <label>Điểm đến</label>
                  <input
                    type='text'
                    id='to_loc'
                    name='to_loc'
                    className='form-control'
                    placeholder='Nhập điểm đến'
                    autocomplete="off"
                    required
                  />
                  <label>Thời gian</label>
                  <DatePicker
                    selected={startDate}
                    showTimeSelect
                    locale="vi"
                    name="on_time"
                    timeFormat="HH:mm"
                    className="form-control"
                    dateFormat="dd/MM/yyyy HH:mm aa"
                    onChange={(date) => setStartDate(date)} />
                  <label>Loại Xe</label>
                  <select className='form-control' name="car_type" id="cars">
                    <option value="xe4cho">Xe 4 chỗ</option>
                    <option value="xe7cho">Xe 7 chỗ</option>
                    <option value="xe16cho">Xe 16 chỗ</option>
                    <option value="tuvanxe">Tôi cần tư vấn thêm</option>
                  </select>
                  <label>Họ & Tên</label>
                  <input
                    type='text'
                    id='hoTen'
                    name='from_name'
                    className='form-control'
                    placeholder='Nhập họ và tên'
                    autocomplete="off"
                    required
                  />
                  <label>Số Điện Thoại</label>
                  <input
                    type='text'
                    id='name'
                    name='from_sdt'
                    className='form-control'
                    placeholder='Nhập số điện thoại'
                    autocomplete="off"
                    required
                  />
                  <div style={{ marginTop: '20px', width: '100%'}}>
                    <button type='submit' disabled={isSent} className='btn-submit-form btn-submit btn btn-custom btn-lg'>
                      {isSent ? 'Đã gửi yêu cầu, xin cảm ơn.' : 'Gửi yêu cầu đặt xe'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
