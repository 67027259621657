import { Image } from "./Image";
import './CacLoaiXe.scss'

export const CacLoaiXe = (props) => {
  return (
    <div id='cac-loai-xe' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Đa dạng dòng xe</h2>
          <p>
            Du Lịch An Khang có hơn 100+ xe mới từ 4-45 chỗ cho quý khách có nhiều sự lựa chọn.
          </p>
        </div>
        <div className='row'>
          <div className='cac-loai-xe-items'>
            {props.data
              ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                  <div className='caption caption-xe'>
                    <h4>{d.title}</h4>
                  </div>
                  <div className='caption'>
                    <p>{d.description}</p>
                  </div>
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
    </div>
  )
}
