import { useState } from 'react'
import emailjs from 'emailjs-com'
import './LienHe.scss'

const initialState = {
  name: '',
  soDienThoai: '',
  message: '',
}
export const LienHe = (props) => {
  const [isSent, setIsSent] = useState(false)
  const [{ name, soDienThoai, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    if (isSent === 'loading') {
      return
    }
    e.preventDefault()
    setIsSent('loading')
    emailjs
      .sendForm(
        'service_nhk0qx1', 'template_z512no8', e.target, 'user_sACUySubu59xIIMdarQlD'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          setIsSent(true)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <div>
      <div id='lien-he'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Đặt xe ngay hôm nay</h2>
                <p>
                  Chúng tôi sẽ liên hệ với quý khách ngay khi nhận được thông tin đặt xe.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        style={{ borderRadius: "4px" }}
                        name='from_name'
                        className='form-control'
                        placeholder='Tên hành khách'
                        required
                        autocomplete="off"
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='number'
                        style={{ borderRadius: "4px" }}
                        id='soDienThoai'
                        name='from_sdt'
                        className='form-control'
                        placeholder='Số điện thoại'
                        required
                        autocomplete="off"
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    style={{ borderRadius: "4px" }}
                    className='form-control'
                    rows='4'
                    placeholder='Lời nhắn đặt xe'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' disabled={isSent} className='btn-submit-form btn btn-custom btn-lg'>
                  {isSent ? 'Đã gửi yêu cầu, xin cảm ơn.' : 'Gửi yêu cầu đặt xe'}
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 lien-he-info'>
            <div className='lien-he-item'>
              <h3>Thông tin liên lạc</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Địa chỉ
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='lien-he-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Điện Thoại & Zalo
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='lien-he-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
