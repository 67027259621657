import { useState, useEffect } from "react";
import { Navigation } from "./components/Navigation";
import { TopHeader } from "./components/TopHeader";
import { PhanHoi } from "./components/PhanHoi";
import { TongQuan } from "./components/TongQuan";
import { GioiThieu } from "./components/GioiThieu";
import { CacLoaiXe } from "./components/CacLoaiXe";
import { DiaDiem } from "./components/DiaDiem";
import { LienHe } from "./components/LienHe";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <TopHeader />
      <GioiThieu data={data.gioiThieu} />
      <TongQuan data={data.tongQuan} />
      <DiaDiem data={data.diaDiem} />
      <CacLoaiXe data={data.cacLoaiXe} />
      <PhanHoi data={data.phanHoi} />
      <LienHe data={data.lienHe} />
      <div className="wrapper">
        <div className="ring">
          <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
            <div className="coccoc-alo-ph-circle"></div>
            <div className="coccoc-alo-ph-circle-fill"></div>
            <a href="tel:0932.046.246">
              <div className="coccoc-alo-ph-img-circle"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
